<template>
  <div class="container">
    <div ref="echart" style="width: 30vw; height: 30vw"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Pubchart",
  computed: {
    ...mapGetters("backstage", ["pub_dateAndCount"]),
  },
  data() {
    return {
      option: {
        title: {
          text: "文章发布统计",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "horizontal",
          top: "bottom",
          left: "center",
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            // magicType: { type: ["line", "bar"] },
            // restore: {},
            saveAsImage: {},
          },
          left: "right",
          // orient: "vertical",
          orient: "horizontal",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          // data: [
          //   "2021-4-1",
          //   "Tue",
          //   "Wed",
          //   "Thu",
          //   "Fri",
          //   "Sat",
          //   "Sun",
          //   "2021-4-1",
          // ],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: [
          {
            name: "发布文章数",
            type: "line",
            data: [],
            // data: [10, 11, 13, 11, 12, 12, 9, 9],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [
                { type: "average", name: "Avg" },
                [
                  {
                    symbol: "none",
                    x: "90%",
                    yAxis: "max",
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "start",
                      formatter: "Max",
                    },
                    type: "max",
                    name: "最高点",
                  },
                ],
              ],
            },
          },
          // {
          //   name: "Lowest",
          //   type: "line",
          //   data: [1, -2, 2, 5, 3, 2, 0],
          //   markPoint: {
          //     data: [{ name: "周最低", value: -2, xAxis: 1, yAxis: -1.5 }],
          //   },
          //   markLine: {
          //     data: [
          //       { type: "average", name: "Avg" },
          //       [
          //         {
          //           symbol: "none",
          //           x: "90%",
          //           yAxis: "max",
          //         },
          //         {
          //           symbol: "circle",
          //           label: {
          //             position: "start",
          //             formatter: "Max",
          //           },
          //           type: "max",
          //           name: "最高点",
          //         },
          //       ],
          //     ],
          //   },
          // },
        ],
      },
    };
  },
  methods: {
    ...mapActions("backstage", {
      getPubAndArticleCount: "getPubAndArticleCount",
    }),
    initChart() {
      let chartDom = this.$refs.echart;
      let myChart = this.echarts.init(chartDom);
      this.option && myChart.setOption(this.option);
    },
  },
  mounted() {
    this.getPubAndArticleCount();
    // 先传数据再实例化
    setTimeout(() => {
      this.option.xAxis.data = this.pub_dateAndCount.dates;
      this.option.series[0].data = this.pub_dateAndCount.values;
      this.initChart();
    }, 500);
  },
};
</script>

<style lang="less" scoped>
.container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 40px;
}
</style>