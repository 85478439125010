<template>
  <div class="container">
    <div ref="artcates" style="width: 30vw; height: 30vw"></div>
    <!-- <div ref="states" style="width: 600px; height: 400px"></div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Piechart",
  computed: {
    ...mapGetters("backstage", ["newCateArticleCount"]),
  },
  data() {
    return {
      option: {
        title: {
          text: "文章分类统计",
          subtext: "各类别文章数量",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          top: "bottom",
          left: "center",
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none",
            // },
            dataView: { readOnly: false },
            // magicType: { type: ["line", "bar"] },
            // restore: {},
            saveAsImage: {},
          },
          left: "right",
          // orient: "vertical",
          orient: "horizontal",
        },
        series: [
          {
            name: "文章数量",
            type: "pie",
            radius: "60%",
            data: [],
            // data: [
            //   { value: 1048, name: "Search Engine" },
            //   { value: 735, name: "Direct" },
            //   { value: 580, name: "Email" },
            //   { value: 484, name: "Union Ads" },
            //   { value: 300, name: "Video Ads" },
            // ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("backstage", {
      getCateArticleCount: "getCateArticleCount",
    }),
    initChart() {
      let chartDom = this.$refs.artcates;
      //   let chartDom2 = this.$refs.states;
      let myChart = this.echarts.init(chartDom);
      //   let myChart2 = this.echarts.init(chartDom2);
      this.option && myChart.setOption(this.option);
      //   this.option && myChart2.setOption(this.option);
    },
  },
  mounted() {
    this.getCateArticleCount();
    // 先传数据再实例化
    setTimeout(() => {
      this.option.series[0].data = this.newCateArticleCount.cate_ArticleCount;
      this.initChart();
    }, 500);
  },
};
</script>

<style lang="less" scoped>
.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  margin: 40px;
}
</style>