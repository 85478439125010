<template>
  <div class="index">
    <h1>{{ myUserInfo.user_name }},欢迎来到管理系统!</h1>
    <div class="chart">
      <Piechart />
      <Pubchart />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { mapActions } from "vuex";
import Piechart from "../../../components/common/Piechart.vue";
import Pubchart from "../../../components/common/Pubchart.vue";
export default {
  name: "Index",
  data() {
    return {};
  },
  components: { Piechart, Pubchart },
  computed: {
    ...mapState("user", ["myUserInfo"]),
  },
  methods: {
    // ...mapActions("backstage", {
    //   getCateArticleCount: "getCateArticleCount",
    //   getPubAndArticleCount: "getPubAndArticleCount",
    // }),
  },
  mounted() {
    // this.getCateArticleCount();
    // this.getPubAndArticleCount();
  },
};
</script>

<style lang="less" scoped>
.index {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  .chart {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
</style>

